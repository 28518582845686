import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/abel"; // Agency FB
import "@fontsource/audiowide";

import { Navigation } from "./index";


const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 px-10 py-10 flex-grow">
      <div className="flex flex-wrap justify-center w-full mt-10">
        <div className="max-w-xl">
          <h2 className="text-4xl">Our Mission</h2>
          <p>Our mission is to spread information we have learned about CBD products to consumers.</p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center w-full mt-10">
        <StaticImage
          alt="Marijuana leaves and jars."
          className="w-full md:w-1/2 lg:w-1/3 mt-10"
          src="../../../../images/cbd-now-mission.jpg"
          loading="eager"
          objectFit="cover"
        />
      </div>
      {/* w-1/3 linear gradient transparent div w-full z-10 */}
      <div className="flex mt-10 w-full justify-center">
        <div className="relative flex flex-wrap mx-auto bg-transparent justify-center items-center max-w-xl py-5">
          <div className="absolute left-0 top-0 bottom-0 w-1/3 bg-cbd-now-red z-20" />
          <div className="absolute left-0 top-0 bottom-0 w-2/3 bg-cbd-now-yellow ml-1/3 z-10" />
          <div className="absolute inset-0 bg-cbd-now-blue" />
          <h2 className="text-4xl w-full text-center z-30 my-0 text-white" style={{ fontFamily: "Audiowide", textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>Best Selection of Video Games</h2>
          <button className="mt-1 py-1 px-5 bg-cbd-now-blue rounded-full text-white border border-white outline-none focus:ring-4 focus:ring-cbd-now-blue z-30" style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>Click Here!</button>
        </div>
      </div>
    </div>
  );
};

const CBDNowPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Abel" }}>
      <Navigation />
      <Body />
      <Navigation />
    </div>
  );
};

export default CBDNowPage;
